$light-primary-color: #6D58BF;
$light-color: #372e4a;
$light-bg-primary: #faf8ff;
$dark-primary-color: #9b83de;
$dark-color: #d4d2d9;
$dark-bg-primary: #1a1624;

$max-width: 48rem;

// font sizes
$base-font-size: 1rem !default;
$h6-font-size: $base-font-size * 0.628 !default;
$h5-font-size: $base-font-size * 1     !default;
$h4-font-size: $h5-font-size * 1.6   !default;
$h3-font-size: $h4-font-size * 1.4   !default;
$h2-font-size: $h3-font-size * 1.3   !default;
$h1-font-size: $h2-font-size * 1.2   !default;

:root {
  --font-serif: 'Abhaya Libre', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', serif;
  --font-family: var(--font-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --h6: #{$h6-font-size};
  --h5: #{$h5-font-size};
  --h4: #{$h4-font-size};
  --h3: #{$h3-font-size};
  --h2: #{$h2-font-size};
  --h1: #{$h1-font-size};

  // Color
  // Dark
  --primary-color: #{$dark-primary-color};
  --color: #{$dark-color};
  --bg-primary: #{$dark-bg-primary};
  // Light
  @media screen and (prefers-color-scheme: light) {
    --primary-color: #{$light-primary-color};
    --color: #{$light-color};
    --bg-primary: #{$light-bg-primary};
  }

  --link-color: var(--primary-color);
  --link-color-secondary: var(--primary-color);
  --max-width: #{$max-width};
}
