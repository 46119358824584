@import "var";
@import "mixin";
@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;600;800&family=Montserrat&display=swap');
html {
  font-family: var(--font-family);
  font-size: 1.5rem;
  line-height: 1.4;
  height: 100%;
  @media screen and (max-width: 800px) {
    font-size: calc( 1rem + (24 - 16) * (100vw - 200px) / (800 - 200) );
  }
}

body {
  color: var(--color);
  background: var(--bg-primary);
  height: 88%;
  max-width: var(--max-width);
  margin: auto;
  padding: 0 2rem;
  display: flex;
  section {
    margin: auto;
    padding: 2rem 0;
  }
}

a {
  color: var(--link-color);
  &:not(:hover) {
    text-decoration: none;
  }
}

