@mixin headings($min: 1, $max: 6) {
  @for $i from $min through $max {
    h#{$i} {
      font-family: var(--font-serif);
      font-size: var(--h#{$i});
      font-weight: 800;
      line-height: 1.4;
    }
  }
}
@include headings();
