@import url(https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;600;800&family=Montserrat&display=swap);
:root{--font-serif: 'Abhaya Libre', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', serif;--font-family: var(--font-serif);-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;--h6: .628rem;--h5: 1rem;--h4: 1.6rem;--h3: 2.24rem;--h2: 2.912rem;--h1: 3.4944rem;--primary-color: #9b83de;--color: #d4d2d9;--bg-primary: #1a1624;--link-color: var(--primary-color);--link-color-secondary: var(--primary-color);--max-width: 48rem}@media screen and (prefers-color-scheme: light){:root{--primary-color: #6D58BF;--color: #372e4a;--bg-primary: #faf8ff}}h1{font-family:'Abhaya Libre', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', serif;font-family:var(--font-serif);font-size:3.4944rem;font-size:var(--h1);font-weight:800;line-height:1.4}h2{font-family:'Abhaya Libre', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', serif;font-family:var(--font-serif);font-size:2.912rem;font-size:var(--h2);font-weight:800;line-height:1.4}h3{font-family:'Abhaya Libre', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', serif;font-family:var(--font-serif);font-size:2.24rem;font-size:var(--h3);font-weight:800;line-height:1.4}h4{font-family:'Abhaya Libre', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', serif;font-family:var(--font-serif);font-size:1.6rem;font-size:var(--h4);font-weight:800;line-height:1.4}h5{font-family:'Abhaya Libre', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', serif;font-family:var(--font-serif);font-size:1rem;font-size:var(--h5);font-weight:800;line-height:1.4}h6{font-family:'Abhaya Libre', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', serif;font-family:var(--font-serif);font-size:.628rem;font-size:var(--h6);font-weight:800;line-height:1.4}html{font-family:'Abhaya Libre', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', serif;font-family:var(--font-family);font-size:1.5rem;line-height:1.4;height:100%}@media screen and (max-width: 800px){html{font-size:calc( 1rem + (24 - 16) * (100vw - 200px) / (800 - 200))}}body{color:#d4d2d9;color:var(--color);background:#1a1624;background:var(--bg-primary);height:88%;max-width:48rem;max-width:var(--max-width);margin:auto;padding:0 2rem;display:flex}body section{margin:auto;padding:2rem 0}a{color:#9b83de;color:var(--link-color)}a:not(:hover){text-decoration:none}


